import requestResult from '@/common/request/request'
import { TradeDirection } from '@/types'

export enum AnalysisType {
  ADVICE = 1,
  NORMAL,
}

export type AnalysisSchema = {
  type: AnalysisType.ADVICE;
  code: string;
  id: number;
  createTime: number;
  direction: TradeDirection;
  url: string;
  informactionContent: string;
  informactionAbstract: string;
  imgDay: string;
  imgHour: string;
  contentTitle: string;
  contentDay: string;
  contentHour: string;
}

export const readAnalysis = requestResult<Array<AnalysisSchema>>('/api/tradopportunities/selectList')

export const readDetail = requestResult<AnalysisSchema>('/api/tradopportunities/detail')
